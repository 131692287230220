// 'use strict';

var rankflow = require('bumpchart'),
    airportNames = require('names').airportNames,
    gsrTable = null,
    tweetTable = null;

console.log(airportNames);

var series = [
    {'rank': 1,
             'ranks': [4, 5, 6, 4, 4, 5, 5, 5, 6, 6, 6],
             'text': 'MIA'},
            {'rank': 2,
             'ranks': [17, 9, 10, 10, 11, 12, 12, 13, 12, 12, 12],
             'text': 'IAH'},
            {'rank': 3,
             'ranks': [15, 14, 13, 11, 9, 9, 9, 9, 9, 9, 9],
             'text': 'LAX'},
            {'rank': 4,
             'ranks': [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
             'text': 'JFK'},
            {'rank': 5,
             'ranks': [10, 12, 12, 13, 14, 14, 15, 15, 15, 15, 15],
             'text': 'SEA'},
            {'rank': 6,
             'ranks': [18, 18, 17, 17, 15, 15, 14, 11, 11, 11, 11],
             'text': 'MCI'},
            {'rank': 7,
             'ranks': [2, 3, 3, 6, 6, 6, 6, 7, 5, 4, 3],
             'text': 'ATL'},
            {'rank': 8,
             'ranks': [9, 11, 11, 12, 13, 13, 14, 14, 14, 14, 14],
             'text': 'EWR'},
            {'rank': 9,
             'ranks': [13, 13, 14, 14, 12, 10, 10, 10, 10, 10, 10],
             'text': 'DEN'},
            {'rank': 10,
             'ranks': [11, 6, 8, 9, 10, 11, 11, 12, 13, 13, 13],
             'text': 'DTW'},
            {'rank': 11,
             'ranks': [7, 8, 9, 8, 8, 8, 8, 8, 8, 8, 8],
             'text': 'ORD'},
            {'rank': 12,
             'ranks': [8, 7, 7, 7, 7, 7, 7, 6, 7, 7, 7],
             'text': 'PHX'},
            {'rank': 13,
             'ranks': [5, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
             'text': 'BOS'},
            {'rank': 14,
             'ranks': [3, 4, 4, 3, 3, 4, 4, 4, 4, 5, 5],
             'text': 'LAS'},
            {'rank': 15,
             'ranks': [16, 17, 18, 18, 18, 18, 18, 18, 18, 18, 18],
             'text': 'LGA'},
            {'rank': 16,
             'ranks': [6, 10, 5, 5, 5, 3, 3, 3, 3, 3, 4],
             'text': 'PHL'},
            {'rank': 17,
             'ranks': [12, 16, 15, 15, 16, 16, 16, 16, 17, 17, 17],
             'text': 'CLT'},
            {'rank': 18,
             'ranks': [14, 15, 16, 16, 17, 17, 17, 17, 16, 16, 16],
             'text': 'DFW'}
        ];


var App = {
  init: function () {
	d3.json('/airport/state/' + initial_state_year, function (current_series) {
	    console.log('Server returns this about current state ', current_series);
	    rankflow.generate({
	      id: 'myChart',
	      series: current_series['series'],
	    }).on('mouseup', function (airport, column) {
	      console.log('User clicked on ' + airport + ' in column ' + column);
	      d3.json('/airport/info/' + airport, function (data) {
		console.log('Server returns this about ' + airport, data);
		App.populateDetails(airport, data);
	      });
	    }).on('dragend', function (airport, column, newRank, oldRank) {
	      console.log('User just moved ' + airport + ' in column ' + column + ' to new rank ' + newRank);

	      d3.json('/airport/dragged/' + airport + '?n=' + newRank + '&o=' + oldRank + '&c=' + column, function (data) {
		console.log('Server has this to say about dragging ' + airport, data);
		rankflow.rearrange(data);
	      });
	    });
        });
  },

  populateDetails: function (airport, data) {
    var airColor = rankflow.colorScale()(airport);
    var airStr = '<span class="class-block" style="background-color:' + airColor + '">' + airport + '</span>';
    if (airportNames.hasOwnProperty(airport) && airportNames[airport].hasOwnProperty('full_name')) {
        airStr = airportNames[airport].full_name + " Airport " + airStr;
    }

    d3.select('#aname').html(airStr);
    
    d3.json('/airport/describe/' + airport + '?c=' + airColor.substring(1), function (data) {
      console.log('Server has this to say about trend description ' + airport, data);
      d3.select('#narrative div').html(data['html']);
    });

   /*
    if (airport === 'MIA') {
        d3.select('#narrative div').html("The system places <span class=\"MIA\">MIA</span> in the top 10 list of airports ranked by estimated threat scores. Two trends appear for Miami International Airport. First trend is a steady increase in relative ranking starting <span class=\"text-date\">Jun'13</span> through  <span class=\"text-date\">Oct'13</span>.  It has peak rank of <span class=\"text-rank\">1</span> for four consecutive quarters starting from  <span class=\"text-date\">Oct'13</span> through  <span class=\"text-date\">Sept'14</span>. The second trend is steady decline in relative ranking from  <span class=\"text-date\">Oct'14</span> through <span class=\"text-date\">Jun'15</span>. Most recent available ranking for Miami International Airport is in the top 5 (rank  <span class=\"text-rank\">1</span>).");
    } else {
        d3.select('#narrative div').text('');
    }
   */

    App.populateTweets(data.tweets, airport);
    App.populateGSR(data.gsr_events);
  },

  populateGSR: function (gsrEvents) {
    // d3.select('#gsr div') //.text(JSON.stringify(gsrEvents.slice(0, 10)));
    //     .select('gsr')
    var gsrTableData = gsrEvents.map(function (d) {
        return [
            //null,
            d.eventType,
            '<span class="text-date">' + moment(d.date, 'M/D/YY').format('YYYY-MM-DD') + '</span> ' + d.description
        ];
    });

    if (gsrTable === null) {
        gsrTable = $("#gsr-table").dataTable({
            data: gsrTableData,
            columns: [
                //{ title: "" },
                { title: "Type" },
                { title: 'Description' },
            ],
            //columnDefs: [ {
            //    orderable: false,
            //    className: 'select-checkbox',
            //    targets:   0
            //} ],
            //select: {
            //    style:    'os',
           //     selector: 'td:first-child'
            //},
            language: {
                select: {
                    rows: {
                        _: "%d events removed",
                        0: "",
                        1: "1 tweet removed"
                    }
                }
            },
            "search": {
                "regex": true
            },
            order: [[ 1, 'asc' ]]
        });
    } else {
        gsrTable.fnClearTable();
        gsrTable.fnAddData(gsrTableData);
        gsrTable.fnDraw();
    }
  },

  populateTweets: function (tweets, airport) {
    //d3.select('#tweets div').text(JSON.stringify(tweets.slice(0, 10)));
    // d3.select('#tweets div').text(JSON.stringify(Object.keys(tweets).map(function(key){return tweets[key]}).slice(0, 10)));

    // tweets.filter(function (d) {
    //     return d.description.indexOf('Laser') !== -1 || d.description.indexOf('Long Island') !== -1;
    // });
    var tweetTableData = Object.keys(tweets).sort(function (a, b) {
        return tweets[b].timestamp - tweets[a].timestamp;
    })
    .slice(15)
    .map(function (d) {
        return [
            null,
            '<span class="text-date">' + moment(tweets[d].timestamp, 'X').format('YYYY-MM-DD') + '</span> ' + tweets[d].text,
            (!!tweets[d].score ? Math.round(tweets[d].score * 100) / 100 : 0),
            tweets[d].tweetid,
            airport,
        ];
    });

    if (tweetTable === null) {
        tweetTable = $("#tweet-table").DataTable({
            data: tweetTableData,
            lengthMenu: [[10, 25, 50, -1], [10, 25, 50, "All"]],
            dom: 'Blfrtip',
            columns: [
                { title: "" },
                { title: "Tweet" },
                { title: "Threat Score" },
                { title: "TweetId" },
                { title: "Airport Name" },
            ],
            columnDefs: [ {
                orderable: false,
                className: 'select-checkbox',
                targets:   0
            }, 
                {
                "targets": [ 3 ],
                "visible": false,
                "searchable": false
                },
                {
                "targets": [ 4 ],
                "visible": false,
                "searchable": false
                },
            ],
            select: {
                style:    'os',
                selector: 'td:first-child'
            },
            buttons: [ 
                'selectAll',
                'selectNone',
                { 
                    text: 'Re-rank Airports',
                    //className: 'btn btn-primary',
                    action: function () {
                        var airport_name = "";
                        var tweetids = $.map(tweetTable.rows(
                            {
                                selected: true, 
                                search: 'applied',
                            } ).data(), function(d){
                                airport_name = d[4];
                                //console.log("Airport {" + airport_name + "}: " + d);
                                return d[3];
                        });
                        //console.log(tweetids);
                        console.log("Selected airport " + airport_name); 
                        d3.json('/airport/rerank/' + airport_name + '?tweets=' + JSON.stringify(tweetids), function (data) {
                          console.log('Server has this to say about trend description ' + airport_name, data);
		                  rankflow.rearrange(data);
                        });
                    }
                }
            ],
            language: {
                select: {
                    rows: {
                        _: "%d tweets selected",
                        0: "",
                        1: "1 tweet selected"
                    }
                }
            },
            order: [[ 1, 'asc' ]],
        });


    } else {
        tweetTable.clear().rows.add(tweetTableData).draw();
    }
    /*
    tweetTable.buttons().disable();
    tweetTable.on( 'select', function ( e, dt, type, indexes ) {
        if ( type === 'row' ) {
            if ( tweetTable.rows( { selected: true } ).indexes().length === 0 ) {
                tweetTable.buttons().disable();
            }else {
                tweetTable.buttons().enable();
            }
        }
    });*/
  },
};

module.exports = App;
