/* jshint node: true */
// 'use strict';

var colors = [
  '#ae017e',
  '#e31a1c',
  '#fb9a99',
  '#b15928',
  '#878787',
  '#1f78b4',
  '#a63603',
  '#fdbf6f',
  '#33a02c',
  '#8c510a',
  '#a6cee3',
  '#6a3d9a',
  '#ff7f00',
  '#253494',
  '#276419',
  '#3690c0',
  "#9970ab",
  '#b2df8a',
];

var availWidth,
  availHeight,
  labelWidth,
  labelHeight,
  columns,
  x,
  y,
  line = d3.svg.line(),
  callbacks = {},
  dragging = {},
  colorScale = d3.scale.ordinal().range(colors),
  padding = {
    top: 15,
    right: 15,
    bottom: 15,
    left: 15,
  };

function computeColumns(series) {
  // Make array of arrays to fill with airports
  var startCols = [];
  for (var i = series[0].ranks.length - 1; i >= 0; i--) {
    startCols.push([]);
  }

  // Add {rank: ..., text: ...} to startCols for each column regardles of
  // rank order
  var computed = series.reduce(function (prev, curr) {
    curr.ranks.forEach(function (d, i) {
      prev[i].push({
        rank: d,
        text: curr.text,
      });
    });
    return prev;
  }, startCols);
  
  // Sort each column in computed by rank order
  computed.forEach(function (d) {
    d.sort(function (a, b) {
      return a.rank - b.rank;
    });
  });

  // Compute columns of {name: ..., ranks: ...} where name is the numerical
  // index of the column and ranks is an array of airport names in sorted order.
  columns = computed.map(function (column, i) {
    return {
      name: i,
      ranks: column.map(function (d) {
        return d.text;
      })
    };
  });

  // Make an object for each column in dragging
  columns.forEach(function (d) {
    dragging[d.name] = {};
  });

  console.log(columns);
}

function computeScales() {
  x = d3.scale.ordinal()
      .domain(columns.map(function (d) {
        return d.name;
      }))
      .rangePoints([labelHeight, availWidth - labelWidth - labelHeight], 1);

  // Set up object of y scales. Keys are column index, values are scales.
  // Each scale maps airport name to y position for that index.
  y = {};
  columns.forEach(function (d) {
    y[d.name] = d3.scale.ordinal()
        .domain(d.ranks)
        .rangePoints([labelHeight, availHeight - labelHeight], 1);
  });
}

function rearrange(config) {
  computeColumns(config.series);
  computeScales();

  d3.selectAll('.cg').selectAll('use')
    .transition().duration(500)
    .attr("transform", function (d) {
      var q = d3.select(this.parentNode).datum().name;
      return "translate(" + x(q) + "," + y[q](d3.select(this).attr('href').slice(1)) + ")";
    });

  d3.selectAll('.sg')
    .data(columns)
    .selectAll('path')
    .data(function (d) {
      return d.ranks;
    });

  d3.selectAll('.sg').selectAll('path')
    .transition().duration(500)
    .attr('d', path)
    .attr('fill', function (d) {
      return colorScale(d);
    })
    .attr('stroke', function (d) {
      return colorScale(d);
    });
}

var dragBehavior = d3.behavior.drag()
  .origin(function (d) {
    var q = d3.select(this.parentNode).datum().name;
    return {
      y: y[q](d)
    };
  })
  .on('dragstart', function (d) {
    var parent = d3.select(this.parentNode);
    var q = parent.datum().name;

    // save that we are dragging this one
    dragging.initialRank = y[q].domain().indexOf(d);
    dragging[q][d] = y[q][d];

    // Sort airport text groups so that the one we are dragging is
    // drawn last and therefore on top.
    var sorter = makeSorter(d);
    parent.sort(sorter);
    // d3.selectAll('#slope-' + q + ' path').sort(sorter);
    // d3.selectAll('#slope-' + (q-1) + ' path').sort(sorter);
  })
  .on('drag', function (d, i) {
    var q = d3.select(this.parentNode).datum().name;

    // Update current drag position
    dragging[q][d] = Math.min(availHeight, Math.max(0, d3.event.y));

    // deform paths
    d3.selectAll('#slope-' + q + ' path').attr('d', path);
    d3.selectAll('#slope-' + (q-1) + ' path').attr('d', path);

    // sort this column based on current y positions
    d3.select(this.parentNode).datum().ranks.sort(function (a, b) {
      return position(a, q) - position(b, q);
    });

    // reset y.domain based on newly sorted ranks
    y[q].domain(d3.select(this.parentNode).datum().ranks);

    // add transform translate to every airport g in this column
    d3.select(this.parentNode).selectAll('.airport')
      .attr("transform", function(d) {
        var q = d3.select(this.parentNode).datum().name;
        return "translate(" + x(q) + "," + position(d, q) + ")";
      });
  })
  .on('dragend', function (d) {
    var q = d3.select(this.parentNode).datum().name;
    delete dragging[q][d]; // stop dragging this one

    var newRank = y[q].domain().indexOf(d);
    if (newRank !== dragging.initialRank && callbacks.hasOwnProperty('dragend')) {
      callbacks['dragend'](d, q, y[q].domain().indexOf(d), dragging.initialRank);
    }
    delete dragging.initialRank;

    // Move dragged airport and its paths into place
    d3.select(this)
      .transition().duration(500)
        .attr('transform', 'translate(' + x(q) + ',' + y[q](d) + ')');
    d3.selectAll('#slope-' + q + ' path')
      .transition().duration(500)
        .attr('d', path);
    d3.selectAll('#slope-' + (q-1) + ' path')
      .transition().duration(500)
        .attr('d', path);
  });

function generate(config) {
  // Append an svg element
  console.log('begin');

  computeColumns(config.series);

  availWidth = parseInt(window.getComputedStyle(document.getElementById('myChart')).width);
  availHeight = 550;//parseInt(window.getComputedStyle(document.getElementById('myChart')).height);

  labelHeight = ((availHeight - padding.top - padding.bottom) / columns[0].ranks.length) * .75 - 2; // dangerous....
  labelWidth = ((availWidth - labelHeight*32 - padding.right - padding.left) / columns.length) * .75;

  computeScales();

  var svg = d3.select('#' + config.id)
    .append('svg')
    .attr('width', availWidth)
    .attr('height', availHeight);

  // Add definitions for each pill, in the bump chart these will be
  // referenced by their ids
  var edge = labelWidth / 10;
  var pillPath = line([
      [0,0],
      [labelWidth, 0],
      [labelWidth, labelHeight],
      [0, labelHeight],
      // [edge, labelHeight]
      // [0,labelHeight/2],
      // [edge, 0],
      // [labelWidth-edge, 0],
      // [labelWidth, labelHeight/2],
      // [labelWidth-edge, labelHeight],
      // [edge, labelHeight]
  ]) + 'Z';

  var defs = svg.append('defs');
  defs.append('clipPath')
      .attr('id', 'pill')
      .append('path')
      .attr('d', pillPath);

  var pillDefs = defs.selectAll('.pill')
      .data(columns[0].ranks)
      .enter().append('g')
      .attr('id', function (d) {
        return d;
      })
      .attr('class', 'pill');

  pillDefs
      .append('g')
      .attr('clip-path', 'url(#pill)')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', labelWidth)
      .attr('height', labelHeight)
      .attr('fill', function (d) {
        return colorScale(d);
      });

  pillDefs
      .append('text')
      .attr('x', labelWidth / 2)
      .attr('y', labelHeight - 5)
      .text(function (d) {
        return d;
      });

  pillDefs
      .append('path')
      // .attr('class', 'pill-outline')
      .attr('stroke-width', '1px')
      .attr('stroke', function (d) {
        return colorScale(d);
      })
      .attr('fill', 'none')
      .attr('d', pillPath);



  // Add column group g elements
  var cgroup = svg.selectAll('.cg')
      .data(columns)
    .enter().append('g')
      .attr('class', 'cg');

  // For each column add all of its airports
  var airport = cgroup.selectAll('.airport')
      .data(function (d, i) {
        return d.ranks;
      })
      .enter().append('use') // use elements will grab their definition from defs
      .attr('xlink:href', function (d) {
        return '#' + d;
      })
      .attr('class', 'pill-use airport')
      .on('mouseenter', mouseenter)
      .on('mouseout', mouseout)
      .attr("transform", function(d) {
        var q = d3.select(this.parentNode).datum().name;
        return "translate(" + x(q) + "," + y[q](d) + ")";
      })
      .on('click', function (d, i) {
        if (callbacks.hasOwnProperty('click')) {
          var q = d3.select(this.parentNode).datum().name;
          callbacks['click'](d, q);
        }
      })
      .on('mouseup', function (d, i) {
        if (callbacks.hasOwnProperty('mouseup')) {
          var q = d3.select(this.parentNode).datum().name;
          callbacks['mouseup'](d, q);
        }
      })
      .call(dragBehavior);

  // Helper function, return dragging position if airport d is
  // being dragged in column q, else returns its yscale position.
  // Add group for each slope chart
  var sgroup = svg.selectAll('.sg')
      .data(columns)
    .enter().append('g')
      .attr('class', 'sg')
      .attr('id', function (d, i) {
        return 'slope-' + i;
      });

  // For each slope chart, add a g for each slope path
  var slopes = sgroup.selectAll('.slope')
      .data(function (d, i) {
        return d.ranks;
      })
    .enter().append('path')
      // .attr('class', 'slope')
      .attr('stroke-width', '1px')
      .attr('stroke', function (d) {
        return colorScale(d);
      })
      .attr('fill', function (d) {
        return colorScale(d);
      })
      .attr('d', path);

  var rankPadding = 7;

  svg.append('line')
    .attr('class', 'framing')
    .attr('x1', x(0) - rankPadding)
    .attr('x2', x(0) - rankPadding)
    .attr('y1', y[0](columns[0].ranks[0]))
    .attr('y2', y[0](columns[0].ranks[columns[0].ranks.length-1]) + labelHeight);

  svg.append('line')
    .attr('class', 'framing')
    .attr('x1', x(columns.length-1) + rankPadding + labelWidth)
    .attr('x2', x(columns.length-1) + rankPadding + labelWidth)
    .attr('y1', y[0](columns[0].ranks[0]))
    .attr('y2', y[0](columns[0].ranks[columns[0].ranks.length-1]) + labelHeight);

  svg.append('line')
    .attr('class', 'framing')
    .attr('x1', x(0))
    .attr('x2', x(columns.length-1) + labelWidth)
    .attr('y1', y[0](columns[0].ranks[0]) - rankPadding)
    .attr('y2', y[0](columns[0].ranks[0]) - rankPadding);

  var columnGap = (x(columns.length-1) - x(0) - labelWidth*columns.length) / (columns.length-1);

  svg.append('g')
    .selectAll('framing-post')
    .data(columns.slice(1))
    .enter()
    .append('line')
    .attr('class', 'framing-post')
    .attr('x1', function (d, i) {
      return x(i) + labelWidth + columnGap/2;
    })
    .attr('x2', function (d, i) {
      return x(i) + labelWidth + columnGap/2;
    })
    .attr('y1', y[0](columns[0].ranks[0]) - rankPadding)
    .attr('y2', y[0](columns[0].ranks[0]) - rankPadding - labelHeight);

  svg.append('g')
    .selectAll('framing-post')
    .data(columns[0].ranks.slice(1))
    .enter()
    .append('line')
    .attr('class', 'framing-post')
    .attr('x1', x(0) - rankPadding)
    .attr('x2', x(0) - rankPadding - labelHeight)
    .attr('y1', function (d, i) {
      return y[0](d) - 3;
    })
    .attr('y2', function (d, i) {
      return y[0](d) - 3;
    });

  svg.append('g')
    .selectAll('framing-post')
    .data(columns[0].ranks.slice(1))
    .enter()
    .append('line')
    .attr('class', 'framing-post')
    .attr('x1', x(columns.length-1) + rankPadding + labelWidth)
    .attr('x2', x(columns.length-1) + rankPadding + labelWidth + labelHeight)
    .attr('y1', function (d, i) {
      return y[0](d) - 3;
    })
    .attr('y2', function (d, i) {
      return y[0](d) - 3;
    });

  var leftNumberG = svg.append('g')
    .attr('transform', 'translate(' + (x(0) - rankPadding - labelHeight) + ')');
  var rightNumberG = svg.append('g')
    .attr('transform', 'translate(' + (x(columns.length-1) + labelWidth + rankPadding) + ')');

  appendRankNumbers(leftNumberG, columns[0].ranks, y[0], -2);
  appendRankNumbers(rightNumberG, columns[columns.length-1].ranks, y[columns.length-1], 2);

  svg.append('g')
    .selectAll('.columnTitle')
    .data([
        "Jan'13-Mar'13",
        "Apr'13-Jun'13",
        "Jul'13-Sep'13",
        "Oct'13-Dec'13",
        "Jan'14-Mar'14",
        "Apr'14-Jun'14",
        "Jul'14-Sep'14",
        "Oct'14-Dec'14",
        "Jan'15-Mar'15",
        "Apr'15-Jun'15",
        "Jul'15-Aug'15",
      ])
    .enter()
    .append('text')
    .attr('class', 'columnTitle')
    .attr('x', function (d, i) {
      if (i === 0) {
        return x(i) + labelWidth/2 + columnGap/4;
      } else if (i === columns.length-1) {
        return x(i) + labelWidth/2 - columnGap/4;
      } else {
        return x(i) + labelWidth/2;
      }
    })
    .attr('y', y[0](columns[0].ranks[0]) - rankPadding*2)
    .text(function (d) {
      return d;
    });

  return this;
}

function appendRankNumbers(selection, data, yScale, xBuffer) {
  rankNumberG = selection
    .selectAll('.rankNumber')
    .data(data)
    .enter().append('g')
    .attr('class', 'rankNumber');

  // rankNumberG.append('rect')
  //   .attr('x', 0)
  //   .attr('y', function (d) {
  //     return yScale(d);
  //   })
  //   .attr('height', labelHeight)
  //   .attr('width', labelHeight);

  // rankNumberG.append('line')
  //   .attr('class', 'framing-post')
  //   .attr('x1', labelHeight)
  //   .attr('x2', 0)
  //   .attr('y1', function (d, i) {
  //     return yScale(d) - 3;
  //   })
  //   .attr('y2', function (d, i) {
  //     return yScale(d) - 3;
  //   });

  rankNumberG.append('text')
    .attr('x', labelHeight/2 + xBuffer)
    .attr('y', function (d) {
      return yScale(d) + labelHeight/2+4;
    })
    .text(function (d, i) {
      return i+1;
    });
}

function mouseenter(d,i) {
  // defs.selectAll(".pill")
  //   .classed("highlight", function(e) {
  //     return e === d;})
  //   .classed("unhighlight", function(e) {
  //     return e !== d; });
  // g.selectAll(".link")
  //   .classed("highlight", function(e) {return e.id === d.id; })
  //   .classed("unhighlight", function(e) {return e.id !== d.id; });
  // g.selectAll(".start-city")
  //   .classed("highlight", function(e) {return e.id === d.id; })
  //   .classed("unhighlight", function(e) {return e.id !== d.id; });
}

function mouseout(d,i) {
  // defs.selectAll(".pill").classed("highlight", false);
  // defs.selectAll(".pill").classed("unhighlight", false);
  // g.selectAll(".link").classed("highlight", false);
  // g.selectAll(".link").classed("unhighlight", false);
  // g.selectAll(".start-city").classed("highlight", false);
  // g.selectAll(".start-city").classed("unhighlight", false);
}

function position(d, q) {
  var v = dragging[q][d];
  return v == null ? y[q](d) : v;
}

function path (d) {
  var q = d3.select(this.parentNode).datum().name;
  if (q === columns.length - 1) {
    // Since we draw the slopes between q and q+1, do nothing for the
    // rightmost column.
    return '';
  }

  var q2 = d3.select(this.parentNode).datum().name + 1;

  var x1 = x(q);
  var y1 = position(d, q);

  var x2 = x(q2);
  var y2 = position(d, q2);

  var points = [
    [x1-1 + labelWidth, y1 + labelHeight],
    [x1-1 + labelWidth, y1],
    [x2+1, y2],
    [x2+1, y2 + labelHeight]
  ];

  return line(points) + 'Z';
}

function makeSorter (d) {
  return function (a, b) {
    if (a === d) {
      return -1;
    } else if (b === d) {
      return 1;
    }
    return 0;
  };
}

function on (type, func) {
  callbacks[type] = func;
  return this;
};

module.exports = {
    rearrange: rearrange,
    generate: generate,
    on: on,
    colorScale: function () {
      return colorScale;
    }
};
